import styled from '@emotion/styled';
import mediaQueries from 'hooks/Media';
import { ReactNode } from 'react';

interface Props {
  text: ReactNode;
}

export default function TopBanner({ text }:Props) {
  return (
    <BannerContainer>{text}</BannerContainer>
  );
}

const BannerContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 100px; */
  min-height: 72px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  background: #FF672F;
  color:#fff;
  strong{
      font-weight: 600;
      color: #000;
  }
  ${mediaQueries.tablet}{
    padding: 16px 24px;
    font-size: 20px;
    line-height: 24px;
    box-sizing: border-box;
  }
`;
