import TopBanner from 'components/molecules/TopBanner';

export default function TopBannerSection() {
  return (
    <TopBanner text={(
      <>
        <p className="pc">
          SPECIAL OFFER:
          {' '}
          <strong>Get 30% OFF</strong>
          {' '}
          Monthly Subscription! Limited Time Only
        </p>
        <p className="mo">
          Subscribe Now &
          {' '}
          <strong>Save 30%</strong>
          {' '}
          <br />
          on Your Monthly Subscription!
        </p>
      </>
      )}
    />
  );
}
